<template>
  <div class="student-year">
    <div class="student-year__alert" v-if="!isDisabled">
      Анкету на коллективные номинации заполняет руководитель коллектива
    </div>
    <div class="student-year__input">
      <Input
        header="Наименование коллектива (проекта)"
        placeholder="Введите наименование"
        v-model="formData.project_name"
        :error="v$.formData.project_name.$errors.length !== 0"
        :disabled="isDisabled"
      />
      <Input
        header="Год создания"
        placeholder="Введите год создания"
        v-model="formData.project_year"
        :error="v$.formData.project_year.$errors.length !== 0"
        :disabled="isDisabled"
      />
      <Input
        header="ФИО руководителя коллектива (проекта)"
        placeholder="Введите ФИО руководителя"
        v-model="formData.project_maintainer"
        :error="v$.formData.project_maintainer.$errors.length !== 0"
        :disabled="isDisabled"
      />
      <Input
        header="Номер телефона"
        placeholder="Введите номер телефона"
        type="tel"
        :mask="'+7(###)-###-##-##'"
        v-model="formData.project_maintainer_phone"
        :error="v$.formData.project_maintainer_phone.$errors.length !== 0"
        :disabled="isDisabled"
      />
      <Input
        header="Учебное заведение"
        disabled
        v-model="educational_establishment_name"
        readonly
      />
    </div>
    <div class="student-year__btn" v-if="!isDisabled">
      <Button @click="saveInfo" :disabled="!hasData">
        Сохранить изменения
      </Button>
      <Button secondary v-if="false">Сохранить как черновик</Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
import Input from "@/components/Blocks/Input";
import useVuelidate from "@vuelidate/core";
import validations from "@/enums/validations";
import moment from "moment";
import trayValidationsTexts from "@/enums/trayValidationsTexts";
import { mapState } from "vuex";

export default {
  name: "AchievementYearCollectiveData",
  components: { Button, Input },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  watch: {
    user() {
      this.educational_establishment_name =
        this.user.info_educational_establishment?.title_full;
    },
  },
  data() {
    return {
      educational_establishment_name: "",
      isDateInvalid: false,
      formData: {
        project_name: "",
        project_year: "",
        project_maintainer: "",
        project_maintainer_phone: "",
      },
    };
  },
  validations() {
    return { formData: validations.studentYearCollectiveData };
  },
  computed: {
    fieldsInfo() {
      return trayValidationsTexts.studentYearCollectiveData;
    },
    isDisabled() {
      let boolean;
      if (Object.values(this.currentCategoryData).length > 0) {
        if (this.validPeriodForRollback) {
          if (this.currentCategoryData?.status?.key === 0) {
            boolean = false;
          } else boolean = this.currentCategoryData?.status?.key !== 35;
        } else if (this.validPeriodFor35Status) {
          boolean = this.currentCategoryData?.status?.key !== 35;
        } else return true;
      }

      return this.isOldApplication || boolean;
    },
    openRegistrationStage() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item.stage_key === "open_registration";
      });
    },
    checkStatementEmployee() {
      return this.currentProject?.settings?.stages?.data.find((item) => {
        return item?.stage_key === "check_statement_employee";
      });
    },
    validPeriodForRollback() {
      return moment().isBetween(
        moment(this.openRegistrationStage?.start_at),
        moment(this.openRegistrationStage?.end_at),
        "day",
        "[]"
      );
    },
    validPeriodFor35Status() {
      return moment().isBetween(
        moment(this.checkStatementEmployee?.start_at),
        moment(this.checkStatementEmployee?.end_at),
        "day",
        "[]"
      );
    },
    hasData() {
      return Object.values(this.formData).every((item) => {
        return item;
      });
    },
    ...mapState("user", ["user"]),
    ...mapState([
      "achievementYearCollectiveData",
      "statementId",
      "currentCategoryData",
      "currentCategoryId",
      "isOldApplication",
    ]),
  },
  methods: {
    async saveInfo() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$store.dispatch("validationErrors", {
          errors: this.v$.$errors,
          fieldsInfo: this.fieldsInfo,
        });
        return;
      }
      if (this.isDateInvalid) return;

      this.$store.commit("setAchievementYearCollectiveData", this.formData);
      this.$store.commit("user/setDisabledData", [
        "isAYCollectiveDataFilled",
        this.hasData,
      ]);
      this.$router.push({ name: "AchievementYearCollectivePerformance" });
    },
  },
  mounted() {
    this.formData = this.achievementYearCollectiveData;
    // if (
    //   this.achievementYearCollectiveData !== {} &&
    //   Object.values(this.achievementYearCollectiveData).length > 2
    // ) {
    //   this.formData = this.achievementYearCollectiveData;
    // }
    this.educational_establishment_name =
      this.user.info_educational_establishment?.title_full;
  },
};
</script>

<style scoped></style>
